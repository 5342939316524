document.addEventListener("DOMContentLoaded", function() {
    const searchList = document.querySelectorAll(".gfs__search");
    searchList.forEach(el => {
        el.addEventListener('submit', (e) => {
            e.preventDefault();
            const identifier = el.querySelector("input[type='text']").value;
            if (identifier !== '') {    
                fetch('/api/doc-search/' + identifier)
                    .then(res => {
                        return res.json();
                    })
                    .then(data => {
                        const list = el.querySelector(".gfs__search__list");
                        list.classList.toggle("list--empty", !data.length);
                        if (data.length) {
                            let html = '';
                            data.forEach(file => {
                                html += "<li><a href='" + file.path + "' download>" + file.name + "</a></li>";
                            });
                            list.innerHTML = '<ul>' + html + '</ul>';
                        }
                        else {
                            list.innerHTML = "<p>" + el.getAttribute("data-noresult") + "</p>";
                        }
                    });
            }
        })
    });
});